import { Poi } from "../interfaces";
import { dateFormat, numberFormat } from "../utils/helpers";
import { HTMLAttributes, PropsWithChildren } from "react";
import { stripHtml } from "string-strip-html";
import usePoiDescriptors from "../hooks/usePoiDescriptors";
import usePoiTranslate from "../hooks/usePoiTranslate";
import ReactCountryFlag from "react-country-flag";

type PoiCardProps = {
	poi: Poi;
} & HTMLAttributes<HTMLDivElement>;

const AbstractHeader = ({ poi, children }: PropsWithChildren<{ poi: Poi }>) => {
	const descriptors = usePoiDescriptors();
	const { title, icon: Icon, countryCode } = descriptors(poi);

	return (
		<div className="flex items-center bg-neutral-200 gap-1 text-xs border-b px-1.5 py-1">
			<div className="flex items-center gap-1">
				<Icon />
				<strong>{title}</strong>
			</div>
			<div>- {children}</div>
			<div className="flex-1"></div>
			<ReactCountryFlag
				countryCode={countryCode}
				style={{
					width: "1.2em",
					height: "auto",
				}}
				svg
			/>
		</div>
	);
};

const EventHeader = ({ poi }: { poi: Poi }) => {
	return (
		<AbstractHeader poi={poi}>
			{dateFormat(poi.event_start_date)}
			{poi.event_end_date && poi.event_end_date > poi.event_start_date && (
				<> ⇾ {dateFormat(poi.event_end_date)}</>
			)}
		</AbstractHeader>
	);
};

const TourHeader = ({ poi }: { poi: Poi }) => {
	return (
		<AbstractHeader poi={poi}>
			{poi.tour_distance && (
				<>{numberFormat(Math.round(poi.tour_distance / 1000))} km</>
			)}
		</AbstractHeader>
	);
};

const PlaceHeader = ({ poi }: { poi: Poi }) => {
	return <AbstractHeader poi={poi}>{poi.address_city}</AbstractHeader>;
};

const ProductHeader = ({ poi }: { poi: Poi }) => {
	return <AbstractHeader poi={poi}>{poi.address_city}</AbstractHeader>;
};

const PoiCard = ({ poi, ...props }: PoiCardProps) => {
	const translate = usePoiTranslate();
	return (
		<div
			className="border bg-white mb-3 last:mb-0 cursor-pointer hover:border-neutral-400"
			{...props}
		>
			{poi && poi.type === "event" && <EventHeader poi={poi} />}
			{poi && poi.type === "tour" && <TourHeader poi={poi} />}
			{poi && poi.type === "place" && <PlaceHeader poi={poi} />}
			{poi && poi.type === "product" && <ProductHeader poi={poi} />}
			<div className="p-2">
				<h2 className="text-sm font-semibold leading-5">
					{translate(poi, "name")}
				</h2>
				{poi.media_link && (
					<img
						src={poi.media_link}
						alt={translate(poi, "media_title")}
						onError={(event: any) => (event.target.style.display = "none")}
						loading="lazy"
						className="aspect-3/1 object-cover rounded-md mt-1"
					/>
				)}
				{translate(poi, "short_description") && (
					<p className="mt-1 line-clamp-5 text-sm text-neutral-600 empty:hidden">
						{stripHtml(translate(poi, "short_description")).result}
					</p>
				)}
			</div>
			{/*<div className="flex text-neutral-600 border-t italic text-xs border-b px-2 py-1">
				{poi.sit_id} - {poi.sit_identifier}
			</div>*/}
		</div>
	);
};

export default PoiCard;
